import apiService from "@/services/api.service";
import store from "@/store";

const AuthService = {
	async login(data) {
		try {
			const response = await apiService.post("/auth/signin/", data);

			if (response.data.access) {
				localStorage.setItem("auth_token", JSON.stringify(response.data));
				await store.dispatch("auth/finishAuthChecking");
				return response;
			}
		} catch (error) {
			localStorage.clear();
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	},

	logout() {
		localStorage.removeItem("auth_token");
	},

	isLoggedIn() {
		const auth_token = JSON.parse(localStorage.getItem("auth_token"));
		return auth_token && auth_token.access;
	},

	getAuthToken() {
		return JSON.parse(localStorage.getItem("auth_token"));
	},

	getLocalRefreshToken() {
		const auth = JSON.parse(localStorage.getItem("auth_token"));
		return auth?.refresh;
	},

	getLocalAccessToken() {
		const auth = JSON.parse(localStorage.getItem("auth_token"));
		return auth?.access;
	},

	updateLocalAccessToken(token) {
		let auth_token = JSON.parse(localStorage.getItem("auth_token"));
		auth_token.access = token;
		localStorage.setItem("auth_token", JSON.stringify(auth_token));
	},

	async resetPassword(data) {
		return await apiService
			.post("/auth/password_reset/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	},
	async resetPasswordValidateToken(data) {
		return await apiService
			.post("/auth/password_reset/validate_token/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	},

	async resetPasswordConfirm(data) {
		return await apiService
			.post("/auth/password_reset/confirm/", data)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	},
};

export default AuthService;
