<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.33333 1H2.66667C2.22464 1 1.80072 1.17559 1.48816 1.48816C1.17559 1.80072 1 2.22464 1 2.66667V14.3333C1 14.7754 1.17559 15.1993 1.48816 15.5118C1.80072 15.8244 2.22464 16 2.66667 16H12.6667C13.1087 16 13.5326 15.8244 13.8452 15.5118C14.1577 15.1993 14.3333 14.7754 14.3333 14.3333V2.66667C14.3333 2.22464 14.1577 1.80072 13.8452 1.48816C13.5326 1.17559 13.1087 1 12.6667 1H9.33333M4.33333 1V8.5L6.83333 6L9.33333 8.5V1M4.33333 1H9.33333"
            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

</template>

<script>
export default {
    name: 'LibraryIcon'
};
</script>
