<template>
    <div>
        <button @click.stop="toggleSidebar" class="hamburger-menu" v-if="isMobile">
            <font-awesome-icon :icon="['far', 'bars']" />
        </button>
        <div :class="['sidebar', { 'sidebar-open': sidebarOpen }]" id="sidebar" @click.stop>
            <div class="logo text-center mb-5">
                <a href="/"><img src="/assets/images/logos/isotipo-sirius.png" style="width: 100%; max-width: 100px" /></a>
            </div>
            <div class="nav-menu">
                <ul>
                    <SidebarMenuItem @click="closeSidebar" to="/home" icon="HomeIcon" text="Home" />
                    <SidebarMenuItem @click="closeSidebar" to="/network" icon="NetworkIcon" text="Network" />
                    <SidebarMenuItem @click="closeSidebar" to="/chat" icon="ChatIcon" text="Chat" />
                    <SidebarMenuItem @click="closeSidebar" to="/library" icon="LibraryIcon" text="Library" />
                    <SidebarMenuItem @click="closeSidebar" to="/calendar" icon="CalendarIcon" text="Calendar" />
                </ul>
                <hr class="separator">
                <div class="profile-item">
                    <SidebarMenuItem @click="closeSidebar" to="/profile" icon="ProfileIcon" text="Profile" />
                </div>
                <div class="signout-item">
                    <a @click="handleLogout" class="menu-link" tabindex="-1">
                        <div class="signout-text">Sign Out</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarMenuItem from '@/components/Sidebar/SidebarMenuItem.vue';

export default {
    name: "SidebarMenu",
    components: {
        SidebarMenuItem
    },
    props: {
        isMobile: {
            type: Boolean,
            required: true
        },
        sidebarOpen: {
            type: Boolean,
            required: true
        },
        toggleSidebar: {
            type: Function,
            required: true
        },
        closeSidebar: {
            type: Function,
            required: true
        }
    },
    mounted() {
        // Ensure focus is managed correctly
        document.addEventListener('keydown', this.handleFocus);
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleFocus);
    },
    methods: {
        handleFocus(event) {
            if (event.key === "Tab" || event.key === "Escape") {
                this.closeSidebar();
            }
        },
        async handleLogout() {
            await this.$store.dispatch("auth/logout");
            this.$router.push("/login");
            this.closeSidebar();
        }
    }
};
</script>

<style scoped>
/* Additional Styles */
#sidebar .nav-menu ul {
    padding-left: 0;
}

.separator {
    border-color: #fff;
    border-width: 1px;
    opacity: 0.5;
}

.profile-item .menu-link .menu-icon {
    width: 30px;
}

.signout-text {
    text-transform: uppercase;
    text-decoration: underline;
    padding-left: 40px;
}
</style>
