// auth.js
export default [
	{
		path: "/login",
		name: "LoginView",
		component: () => import("@/views/Auth/Login.vue"),
		meta: { requiresAuth: false },
	},

	{
		path: "/register",
		name: "RegisterView",
		component: () => import("@/views/Auth/Register.vue"),
		meta: { requiresAuth: false },
	},

	{
		path: "/register/password",
		name: "RegisterPasswordView",
		component: () => import("@/views/Auth/RegisterPassword.vue"),
		meta: { requiresAuth: false },
	},
	{
		path: "/register/verify",
		name: "VerifyEmailView",
		component: () => import("@/views/Auth/VerifyEmail.vue"),
		meta: { requiresAuth: false },
	},
	{
		path: "/password/reset",
		name: "ResetPassword",
		component: () => import("@/views/Auth/ResetPassword.vue"),
		meta: { requiresAuth: false },
	},
	{
		path: "/password/reset/code",
		name: "CodeResetPassword",
		component: () => import("@/views/Auth/CodeResetPassword.vue"),
		meta: { requiresAuth: false },
	},
	{
		path: "/password/reset/confirm",
		name: "ConfirmResetPassword",
		component: () => import("@/views/Auth/ConfirmResetPassword.vue"),
		meta: { requiresAuth: false },
	},
	{
		path: "/verify",
		name: "EmailVerified",
		component: () => import("@/views/Auth/EmailVerified.vue"),
		meta: { requiresAuth: false },
	},
];
