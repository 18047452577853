<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="none">
        <path fill="#fff"
            d="M5 8a3.333 3.333 0 0 1-3.333-3.333V2a.667.667 0 0 1 .666-.667H3A.667.667 0 1 0 3 0h-.667a2 2 0 0 0-2 2v2.667a4.653 4.653 0 0 0 2.014 3.826 5.88 5.88 0 0 1 1.986 4.174 4.667 4.667 0 0 0 9.334 0v-.76a2.666 2.666 0 1 0-1.333 0v.76a3.333 3.333 0 0 1-6.667 0 5.88 5.88 0 0 1 2-4.174 4.653 4.653 0 0 0 2-3.826V2a2 2 0 0 0-2-2H7a.667.667 0 1 0 0 1.333h.667A.667.667 0 0 1 8.334 2v2.667A3.333 3.333 0 0 1 5 8Zm8 2.667A1.333 1.333 0 1 1 13 8a1.333 1.333 0 0 1 0 2.666Z" />
    </svg>
</template>

<script>
export default {
    name: 'NetworkIcon'
};
</script>
