// app.js
export default [
	{
		path: "/home",
		name: "HomeView",
		component: () => import("@/views/Pages/Home.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/profile",
		name: "ProfileView",
		component: () => import("@/views/Pages/Profile.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/library",
		name: "LibraryView",
		component: () => import("@/views/Pages/Library.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/network",
		name: "NetworkView",
		component: () => import("@/views/Pages/Network.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/calendar",
		name: "CalendarView",
		component: () => import("@/views/Pages/Calendar.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/chat",
		name: "ChatView",
		component: () => import("@/views/Pages/Chat.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/browse/search",
		name: "SearchResults",
		component: () => import("@/views/Pages/SearchResults.vue"),
		meta: { requiresAuth: true },
	},
];
