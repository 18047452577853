import AuthService from "@/services/auth.service";
import UserService from "@/services/user.service";
import createPersistedState from "vuex-persistedstate";

const user = JSON.parse(localStorage.getItem("auth_token"));
const initialState = user ? { status: { loggedIn: true }, user: JSON.parse(localStorage.getItem("user")), authChecking: true } : { status: { loggedIn: false }, user: null, authChecking: true };

export const auth = {
	namespaced: true,
	state: initialState,
	actions: {
		async login({ commit }, user) {
			commit("setAuthChecking", true);
			try {
				const response = await AuthService.login(user);

				if (response.status === 200) {
					const currentUser = await UserService.get_current_user();
					commit("loginSuccess", currentUser);
				} else {
					commit("loginFailure");
					return Promise.reject(response);
				}

				commit("setAuthChecking", false);
				return Promise.resolve(response);
			} catch (error) {
				commit("loginFailure");
				commit("setAuthChecking", false);
				return Promise.reject(error);
			}
		},
		logout({ commit }) {
			AuthService.logout();
			commit("logout");
		},
		async register({ commit }, user) {
			try {
				const response = await AuthService.register(user);
				commit("registerSuccess");
				return Promise.resolve(response.data);
			} catch (error) {
				commit("registerFailure");
				return Promise.reject(error);
			}
		},
		refreshToken({ commit }, accessToken) {
			commit("refreshToken", accessToken);
		},
		finishAuthChecking({ commit }) {
			commit("setAuthChecking", false);
		},
		async loadUser({ commit }) {
			const authToken = JSON.parse(localStorage.getItem("auth_token"));
			if (authToken && authToken.access) {
				try {
					const currentUser = await UserService.get_current_user();
					commit("loginSuccess", currentUser);
				} catch (error) {
					console.error("Error loading current user:", error);
					commit("logout");
				}
			}
		},
	},
	mutations: {
		loginSuccess(state, user) {
			state.status.loggedIn = true;
			state.user = user;
			localStorage.setItem("user", JSON.stringify(user));
		},
		loginFailure(state) {
			state.status.loggedIn = false;
			state.user = null;
			localStorage.removeItem("user");
		},
		logout(state) {
			state.status.loggedIn = false;
			state.user = null;
			localStorage.removeItem("user");
		},
		registerSuccess(state) {
			state.status.loggedIn = false;
		},
		registerFailure(state) {
			state.status.loggedIn = false;
		},
		refreshToken(state, accessToken) {
			state.status.loggedIn = true;
			state.user = { ...state.user, accessToken: accessToken };
		},
		setAuthChecking(state, checking) {
			state.authChecking = checking;
		},
	},
	plugins: [
		createPersistedState({
			paths: ["auth"],
		}),
	],
};
