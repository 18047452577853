<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.6665 15.9841V7.34921C16.6665 7.1915 16.6304 7.03595 16.5609 6.89489C16.4915 6.75384 16.3907 6.63113 16.2665 6.53651L9.2665 1.20317C9.09341 1.07129 8.88287 1 8.6665 1C8.45013 1 8.2396 1.07129 8.0665 1.20317L1.0665 6.53651C0.942308 6.63113 0.841504 6.75384 0.772077 6.89489C0.702649 7.03595 0.666504 7.1915 0.666504 7.34921V15.9841C0.666504 16.2536 0.771861 16.5119 0.959397 16.7025C1.14693 16.893 1.40129 17 1.6665 17H5.6665C5.93172 17 6.18607 16.893 6.37361 16.7025C6.56115 16.5119 6.6665 16.2536 6.6665 15.9841V12.9365C6.6665 12.6671 6.77186 12.4087 6.9594 12.2182C7.14693 12.0277 7.40129 11.9206 7.6665 11.9206H9.6665C9.93172 11.9206 10.1861 12.0277 10.3736 12.2182C10.5611 12.4087 10.6665 12.6671 10.6665 12.9365V15.9841C10.6665 16.2536 10.7719 16.5119 10.9594 16.7025C11.1469 16.893 11.4013 17 11.6665 17H15.6665C15.9317 17 16.1861 16.893 16.3736 16.7025C16.5611 16.5119 16.6665 16.2536 16.6665 15.9841Z"
            stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

</template>

<script>
export default {
    name: 'HomeIcon'
};
</script>
