import { Modal } from "bootstrap";

export default {
	mounted() {
		// Busca todos los modales abiertos y ciérralos
		const modals = document.querySelectorAll(".modal.show");
		modals.forEach((modal) => {
			const modalInstance = Modal.getInstance(modal) || new Modal(modal);
			modalInstance.hide();
		});

		// Elimina todos los elementos de modal-backdrop
		const backdrops = document.querySelectorAll(".modal-backdrop");
		backdrops.forEach((backdrop) => {
			backdrop.parentNode.removeChild(backdrop);
		});
	},
};
