<template>
    <svg v-if="!profile || !profile.profile_photo" width="800px" height="800px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path fill="#494c4e"
            d="M9 0a9 9 0 0 0-9 9 8.654 8.654 0 0 0 .05.92 9 9 0 0 0 17.9 0A8.654 8.654 0 0 0 18 9a9 9 0 0 0-9-9zm5.42 13.42c-.01 0-.06.08-.07.08a6.975 6.975 0 0 1-10.7 0c-.01 0-.06-.08-.07-.08a.512.512 0 0 1-.09-.27.522.522 0 0 1 .34-.48c.74-.25 1.45-.49 1.65-.54a.16.16 0 0 1 .03-.13.49.49 0 0 1 .43-.36l1.27-.1a2.077 2.077 0 0 0-.19-.79v-.01a2.814 2.814 0 0 0-.45-.78 3.83 3.83 0 0 1-.79-2.38A3.38 3.38 0 0 1 8.88 4h.24a3.38 3.38 0 0 1 3.1 3.58 3.83 3.83 0 0 1-.79 2.38 2.814 2.814 0 0 0-.45.78v.01a2.077 2.077 0 0 0-.19.79l1.27.1a.49.49 0 0 1 .43.36.16.16 0 0 1 .03.13c.2.05.91.29 1.65.54a.49.49 0 0 1 .25.75z" />
    </svg>

    <img v-else :src="profile.profile_photo" class="profile-pic">
</template>

<script>
import UserService from '@/services/user.service.js';

export default {
    name: 'ProfileIcon',
    data(){
        return {
            profile: {},
        }
    },
    methods: {
        async get_profile(){
            try {
                const response = await UserService.get_current_user();
                this.profile = response.data.profile;
                this.isLoading = false;
            } catch (error) {
                console.error('Error getting profile:', error);
            }

        }
    },
    beforeMount(){
        this.get_profile()
    }
};
</script>

<style scoped>
.profile-pic{
    border-radius: 9999px;
    height: 30px;
    width: 30px;
}
</style>
