<template>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.2836 0.385383C16.1108 0.213464 15.8942 0.0926056 15.6574 0.0360903C15.4206 -0.0204249 15.1729 -0.0104093 14.9414 0.0650364L1.5841 4.53158C1.33638 4.61027 1.11747 4.7608 0.955028 4.96414C0.792586 5.16748 0.693899 5.41451 0.671429 5.67405C0.648959 5.93358 0.703715 6.19397 0.828781 6.42233C0.953847 6.65069 1.14361 6.83676 1.37411 6.95707L6.91608 9.70289L9.65511 15.2769C9.7652 15.4951 9.93367 15.6783 10.1417 15.806C10.3497 15.9337 10.589 16.0008 10.8329 16H10.9242C11.1854 15.9807 11.4348 15.8831 11.6399 15.7199C11.8451 15.5567 11.9965 15.3354 12.0746 15.0847L16.594 1.73084C16.6747 1.50013 16.6883 1.25118 16.6334 1.01298C16.5784 0.774788 16.4571 0.557141 16.2836 0.385383ZM2.15017 5.79466L13.8093 1.89559L7.33606 8.3849L2.15017 5.79466ZM10.8968 14.5539L8.30385 9.35509L14.7771 2.86578L10.8968 14.5539Z"
            fill="white" />
    </svg>
</template>

<script>
export default {
    name: 'ChatIcon'
};
</script>
