<template>
    <main class="d-flex flex-column" id="app_root">
        <div v-if="$route.meta.requiresAuth" class="main-page">
            <Sidebar :isMobile="isMobile" :sidebarOpen="sidebarOpen" :toggleSidebar="toggleSidebar" :closeSidebar="closeSidebar" />
            <div v-if="isMobile && sidebarOpen" class="overlay" @click="closeSidebar"></div>
            <div ref="pageContent" :class="['page-content', { 'no-scroll': sidebarOpen }]" @click="handleClickOutside">
                <Navbar />
                <router-view @route-change="resetScroll" />
                <Footer />
            </div>
        </div>
        <router-view v-else @route-change="resetScroll" />
    </main>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Navbar from "@/components/Navbar/Navbar.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
    name: 'App',
    components: {
        Sidebar,
        Navbar,
        Footer,
    },
    setup() {
        const sidebarOpen = ref(false);
        const isMobile = ref(window.innerWidth <= 992);
        const pageContent = ref(null);
        const router = useRouter();

        const toggleSidebar = () => {
            sidebarOpen.value = !sidebarOpen.value;
        };

        const closeSidebar = () => {
            if (isMobile.value) {
                sidebarOpen.value = false;
            }
        };

        const handleResize = () => {
            isMobile.value = window.innerWidth <= 992;
            if (!isMobile.value) {
                sidebarOpen.value = false;
            }
        };

        const handleClickOutside = (event) => {
            if (sidebarOpen.value && isMobile.value) {
                const sidebar = document.getElementById('sidebar');
                if (sidebar && !sidebar.contains(event.target)) {
                    sidebarOpen.value = false;
                }
            }
        };

        const resetScroll = () => {
            if (pageContent.value) {
                pageContent.value.scrollTop = 0;
            }
        };

        onMounted(() => {
            window.addEventListener('resize', handleResize);
            document.addEventListener('click', handleClickOutside);
            router.afterEach(() => {
                resetScroll();
            });
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('click', handleClickOutside);
        });

        return {
            sidebarOpen,
            isMobile,
            toggleSidebar,
            closeSidebar,
            pageContent,
            resetScroll
        };
    }
};
</script>

<style scoped>
/* Scoped styles for App.vue if needed */
</style>
