<template>
    <li class="menu-item">
        <router-link :to="to" class="menu-link" @click="handleClick">
            <div class="menu-icon">
                <component :is="iconComponent" class="svg-icon" />
            </div>
            <div class="menu-text">{{ text }}</div>
        </router-link>
    </li>
</template>

<script>
import HomeIcon from '@/components/Icons/HomeIcon.vue';
import NetworkIcon from '@/components/Icons/NetworkIcon.vue';
import ChatIcon from '@/components/Icons/ChatIcon.vue';
import LibraryIcon from '@/components/Icons/LibraryIcon.vue';
import CalendarIcon from '@/components/Icons/CalendarIcon.vue';
import ProfileIcon from '@/components/Icons/ProfileIcon.vue';

export default {
    name: 'SidebarMenuItem',
    props: {
        to: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true,
            validator(value) {
                return ['HomeIcon', 'NetworkIcon', 'ChatIcon', 'LibraryIcon', 'CalendarIcon', 'ProfileIcon'].includes(value);
            }
        },
        text: {
            type: String,
            required: true
        }
    },
    computed: {
        iconComponent() {
            switch (this.icon) {
                case 'HomeIcon':
                    return HomeIcon;
                case 'NetworkIcon':
                    return NetworkIcon;
                case 'ChatIcon':
                    return ChatIcon;
                case 'LibraryIcon':
                    return LibraryIcon;
                case 'CalendarIcon':
                    return CalendarIcon;
                case 'ProfileIcon':
                    return ProfileIcon;
                default:
                    return null;
            }
        }
    },
    methods: {
        handleClick(event) {
            event.currentTarget.blur(); // Remove focus from the clicked element
            this.$emit('click');
        }
    }
};
</script>

<style>
.menu-item {
    list-style: none;
}

.menu-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    width: 100%;
    border-radius: 50px;
    padding: 7px 20px;
    margin-bottom: 10px;
}

.menu-link .menu-icon {
    position: absolute;
    display: flex;
    align-items: center;
    width: 30px;
}

.menu-link .menu-text {
    padding-left: 40px;
    text-transform: uppercase;
}

.menu-link:hover {
    background-color: var(--sirius-gray-space);
    color: var(--sirius-white);
}

.menu-link:focus,
.menu-link:hover:focus {
    background-color: var(--sirius-white);
    box-shadow: 0 0rem 1.25rem rgba(255, 255, 255, 1.175);
}

.menu-link:focus .menu-text,
.menu-link:focus .menu-icon path {
    color: var(--sirius-gray-space);
    fill: var(--sirius-gray-space);
    stroke: var(--sirius-gray-space);
}

.menu-link:focus.router-link-active {
    background-color: transparent;
    box-shadow: none;
}

.menu-link.router-link-active .menu-text,
.menu-link.router-link-active .menu-icon path,
.menu-link:focus.router-link-active .menu-text,
.menu-link:focus.router-link-active .menu-icon path {
    color: var(--sirius-turquese);
    fill: var(--sirius-turquese);
    stroke: var(--sirius-turquese);
}
</style>
